import { StoryBlokColor, isColorSet } from '../components/StoryBlokColor';

interface IPageStyles {
  primary_color: StoryBlokColor;
  error_color: StoryBlokColor;
  disabled_fill_color: StoryBlokColor;
  disabled_font_color: StoryBlokColor;
  base_fill_color: StoryBlokColor;
  font_color: StoryBlokColor;
}

export const getPageStylesValues = (props: IPageStyles) => {
  const {
    primary_color,
    error_color,
    disabled_fill_color,
    disabled_font_color,
    base_fill_color,
    font_color,
  } = props;

  return {
    primaryColor: isColorSet(primary_color) && primary_color.color,
    errorColor: isColorSet(error_color) && error_color.color,
    disabledFillColor: isColorSet(disabled_fill_color) && disabled_fill_color.color,
    disabledFontColor: isColorSet(disabled_font_color) && disabled_font_color.color,
    baseFillColor: isColorSet(base_fill_color) && base_fill_color.color,
    fontColor: isColorSet(font_color) && font_color.color,
  };
};
