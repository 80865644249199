import React from 'react';
import { Helmet } from 'react-helmet';

import { CookieConsent } from '../components/CookieConsent';
import { EnvironmentContext } from '../components/EnvironmentContext';
import { getComponentForBlok } from '../components/NxComponents';
import { getPageStylesValues } from '../utils/getPageStylesValues';

interface Story {
  story: any;
  trackedPageView: boolean;
  isProd: boolean;
}

class NXPageEntry extends React.Component<any, Story> {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null;
    }

    return NXPageEntry.prepareStory(props);
  }

  static prepareStory(props): Story {
    const story = Object.assign({}, props.pageContext.story);
    story.content = JSON.parse(story.content);

    const isProd =
      typeof window !== 'undefined' &&
      window.location.origin.indexOf('swellenergy.com') > -1 &&
      window.location.origin.indexOf('sbx') === -1;

    return { story, trackedPageView: false, isProd };
  }

  constructor(props) {
    super(props);

    this.state = NXPageEntry.prepareStory(props);
  }

  componentDidMount() {
    if (!this.state.trackedPageView) {
      this.setState({ trackedPageView: true });
    }
  }

  render() {
    const { story, isProd } = this.state;

    const content = story.content;

    return (
      <>
        <Helmet>
          <title>{story.name} - Swell Energy</title>
          <meta name="title" content={`${story.name} - Swell Energy`} />
          <meta property="og:title" content={`${story.name} - Swell Energy`} />
          <meta property="og:site_name" content="Swell Energy" />
          <meta
            property="og:description"
            content="Solar, Storage, and VPP - Home Energy Systems - Swell Energy"
          />
          <meta
            property="og:image"
            content="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="fb:admins" content="swellenergy" />
          <meta name="twitter:title" content={`${story.name} - Swell Energy`} />
          <meta name="twitter:site" content="@swell_energy" />
          <meta
            name="twitter:description"
            content="Solar, Storage, and VPP - Home Energy Systems - Swell Energy"
          />
          <meta
            name="twitter:image"
            content="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
          <link
            rel="image_src"
            href="https://a.storyblok.com/f/89720/1200x630/e6cca19347/swell-og-3.png"
          />
        </Helmet>
        <CookieConsent />
        <EnvironmentContext.Provider
          value={{
            isInEditor: false,
            environment: isProd ? 'prod' : 'dev',
            storyName: story.full_slug,
            pageStyles: getPageStylesValues(content),
          }}
        >
          {getComponentForBlok(content)}
        </EnvironmentContext.Provider>
      </>
    );
  }
}

export default NXPageEntry;
