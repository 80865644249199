import React, { useCallback, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { scriptsList } from '../scripts/scriptsList';

const GA_DISABLE_NAME = `ga-disable-${
  process.env.TARGET_ENV === 'production' ? 'UA-57579537-1' : 'UA-57579537-6'
}`;

const ConsentWrapper = tw.div`z-20 bg-[#3f4041] md:max-w-[960px] md:w-full fixed bottom-4 left-4 rounded-md text-white p-4 right-4 shadow-md`;
const Text = tw.p`m-0`;
const AcceptButton = tw.button`w-[86px] text-[14px] bg-white rounded-md text-black py-1 mr-2`;
const DenyButton = tw.button`w-[86px] text-[14px] bg-[lightgrey] rounded-md text-black py-1`;
const ButtonsWrapper = tw.div`flex justify-end items-center mt-4`;

const env = typeof window === 'undefined' ? global : window;

const fetch = env.fetch;

env.fetch = function (...args) {
  let url = '';
  if (typeof args[0] === 'string') {
    url = args[0];
  }
  if (args[0].constructor === Request) {
    url = args[0].url;
  }
  if (args[0].constructor === URL) {
    url = args[0].href;
  }
  if (!url.includes('calltrk')) {
    return fetch.apply(this, args);
  }
  throw new Error('No calltrk');
};

export const CookieConsent = () => {
  const [show, setShow] = useState(false);

  const loadScripts = useCallback(() => {
    scriptsList.forEach((el) => {
      const script = document.createElement('script');
      if (el.startsWith('http')) script.src = el;
      else script.innerHTML = el;
      script.async = true;
      document.head.appendChild(script);
    });
  }, []);

  useEffect(() => {
    const consentSessionValue = sessionStorage.getItem('swell:consent');
    const consentValue = localStorage.getItem('swell:consent');
    if (!consentValue && !consentSessionValue) return setShow(true);

    if (consentValue) {
      delete window[GA_DISABLE_NAME];
      return loadScripts();
    }

    setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) return null;

  return (
    <ConsentWrapper>
      <Text>
        We use cookies and similar technologies to ensure our website functions effectively,
        generate analytics allowing us to improve our content, provide you personalized ads, and
        understand how visitors interact with our website. By clicking "Accept All", you consent to
        such uses. You can refuse to consent by clicking on "Decline All".
      </Text>
      <ButtonsWrapper>
        <AcceptButton
          onClick={() => {
            delete window[GA_DISABLE_NAME];
            loadScripts();
            setShow(false);
            localStorage.setItem('swell:consent', 'true');
          }}
        >
          Accept All
        </AcceptButton>
        <DenyButton
          onClick={() => {
            setShow(false);
            sessionStorage.setItem('swell:consent', 'false');
          }}
        >
          Decline All
        </DenyButton>
      </ButtonsWrapper>
    </ConsentWrapper>
  );
};
